import {Modal} from 'bootstrap';

let modalAjax = document.getElementById('modal-ajax');

function submitGenericModalForm() {
    let formModal = modalAjax.querySelector("form")
    let formData = new FormData(formModal);

    fetch(formModal.getAttribute('action'), {
        'method': formModal.getAttribute("method"),
        'body': formData
    })
        .then(res => {
            if ("url" in res && res.url.includes("/login")) {
                document.location.reload();
                return false;
            }

            return res.json();
        })
        .then(res => {
            if ("redirectTo" in res) {
                window.location.href = res.redirectTo;
                return;
            }

            if ("refreshPage" in res) {
                window.location.reload();
                return;
            }

            if ("refreshXhr" in res) {
                document.dispatchEvent(new CustomEvent("modal.generic.saved", {
                    bubbles: true,
                    detail: {
                        getAction: () => formModal.getAttribute('action')
                    }
                }));
            }

            if ("success" in res && res.success) {
                Modal.getOrCreateInstance(modalAjax).hide();
                document.dispatchEvent(new CustomEvent("modal.generic.saved", {
                    bubbles: true,
                    detail: {
                        getAction: () => formModal.getAttribute('action')
                    }
                }));

                return;
            }

            if ("template" in res) {
                var doc = new DOMParser().parseFromString(res.template, 'text/html');
                document.querySelector("#modal-ajax .modal-content").innerHTML = doc.querySelector("body").innerHTML;
            }

            return res;
        }).then(res => {
            if (!res.success) {
                initGenericModalEvents();
                document.querySelector("#modal-ajax .modal-content").querySelector('form')?.classList.add("was-validated");
            } else {
                document.querySelector("#modal-ajax .modal-content").querySelector('form')?.classList.remove("was-validated");
            }
        }).catch(res => {});
}

function initGenericModalEvents(refresh = false) {
    let modalForm = modalAjax.querySelector("form");
    if (modalForm !== null) {
        if (!refresh) {
            modalForm.addEventListener("submit", evt => {
                evt.preventDefault();
                submitGenericModalForm();
            });

            if (modalForm.querySelector("button[type='submit']") !== null) {
                modalForm.querySelector("button[type='submit']").addEventListener("click", e => {
                    let validForm = modalAjax.querySelector('form');
                    validForm.classList.add("was-validated");
                });
            }
        }

        modalForm.querySelectorAll(".form-block-xhr").forEach(formBlockXhr => {
            formBlockXhr.querySelectorAll("input:not([type='hidden']):not([type='file']):not([type='date']):not([type='datetime-local']), select").forEach(item => {
                item.addEventListener("change", _ => refreshGenericModalForm(modalForm));
            });
        });
    }
}

function refreshGenericModalForm(form) {
    let formData = new FormData(form);

    formData.forEach((value, key, parent) => {
        if (key.includes("[_token]"))
            formData.delete(key);
    });

    fetch(form.getAttribute("action"), {
        method: form.getAttribute('method'),
        body: formData
    })
        .then(res => res.json())
        .then(data => {
            if ("template" in data) {
                let view = new DOMParser().parseFromString(data.template, "text/html");
                form.querySelectorAll(".form-block-xhr").forEach((item, index) => {
                    item.innerHTML = view.querySelectorAll(".form-block-xhr")[index].innerHTML;
                })
            }
        }).then(_ => {
            initGenericModalEvents(true);
        });
}

if (modalAjax !== null) {
    modalAjax.addEventListener('show.bs.modal', evt => {
        if (evt.relatedTarget.dataset.url !== null) {
            fetch(evt.relatedTarget.dataset.url)
                .then(res => {
                    if (res.redirected) {
                        document.location.href = res.url;
                        return false;
                    }

                    if (res.status === 404) {
                        document.location.reload();
                        return false;
                    }

                    return res.text()
                })
                .then(res => {
                    if (res === false)
                        return;

                    const doc = new DOMParser().parseFromString(res, 'text/html');
                    document.querySelector("#modal-ajax .modal-content").innerHTML = doc.querySelector("body").innerHTML;
                }).then(_ => {
                    initGenericModalEvents();
                });
        }
    });

    modalAjax.addEventListener('hidden.bs.modal', evt => {
        document.querySelector("#modal-ajax .modal-content").innerHTML = "";
    })
}
